import React, { useState, useRef, useMemo } from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import NavBar from "../../components/NavBar/NavBar"
import { nameCapitalized, isSSR } from "../../components/Script/script"

import UnitEqualAnathor from "./component/listOfEveryUnitEqualToAnathor"
import UnitInfo from "./component/unitInformation"
import {
  ConverterWrapper,
  ConverterSideA,
  ConverterSideB,
  ConverterEquals,
} from "./component/unitStyle"

import { Columns, Column, Title } from "../../StyleComponents/styles"

const ListUnitsLink = React.lazy(() => import("./component/unitListLinks"))

const IndexPage = props => {
  const {
    name,
    pathUrl,
    info,
    symbolNode,
    baseUnitNode,
    derivedNode,
    pathpages,
    units,
  } = props.pageContext
  let [valueOne, setvalueOne] = useState("")
  let [valuetwo, setvaluetwo] = useState("")
  let selectOne = useRef("")
  let selectTwo = useRef("")

  function inputOne(e) {
    e.preventDefault()
    setvalueOne(e.target.value)
    if (e.target.value === "") {
      setvaluetwo("")
    } else {
      let result = parseFloat(e.target.value)
      if (name === "temperature") {
        let offset = selectOne.current.selectedOptions[0].dataset.unit
        result = parseFloat(result) + parseFloat(offset)
      }
      result = result * selectOne.current.value
      result = result / selectTwo.current.value
      if (name === "temperature") {
        let offset = selectTwo.current.selectedOptions[0].dataset.unit
        result = parseFloat(result) - parseFloat(offset)
      }
      setvaluetwo(result.toFixed(6))
    }
  }
  function inputtwo(e) {
    e.preventDefault()
    setvaluetwo(e.target.value)
    if (e.target.value === "") {
      setvalueOne("")
    } else {
      let result = parseFloat(e.target.value)
      if (name === "temperature") {
        let offset = selectTwo.current.selectedOptions[0].dataset.unit
        result = parseFloat(result) + parseFloat(offset)
      }
      result = result * selectTwo.current.value
      result = result / selectOne.current.value
      if (name === "temperature") {
        let offset = selectOne.current.selectedOptions[0].dataset.unit
        result = parseFloat(result) - parseFloat(offset)
      }
      setvalueOne(result.toFixed(6))
    }
  }

  function changeSelectOne() {
    changeSelectTwo()
  }

  function changeSelectTwo() {
    if (!isNaN(parseFloat(valuetwo))) {
      let result = parseFloat(valueOne)
      if (name === "temperature") {
        let offset = selectOne.current.selectedOptions[0].dataset.unit
        result = parseFloat(result) + parseFloat(offset)
      }
      result = result * selectOne.current.value
      result = result / selectTwo.current.value
      if (name === "temperature") {
        let offset = selectTwo.current.selectedOptions[0].dataset.unit
        result = parseFloat(result) - parseFloat(offset)
      }
      setvaluetwo(result.toFixed(6))
    }
  }
  return (
    <Layout location={props.location}>
      <SEO
        title={`${nameCapitalized(name)} Unit Converter`}
        description={info}
        keywords={units.map(e => e.name)}
      />
      <NavBar
        listPages={useMemo(() => {
          return [
            { name: "Converter", link: "/units-converter/" },
            { name: `${name} Converter`, link: `/${pathUrl}/` },
          ]
        }, [name, pathUrl])}
      />
      <div className="container">
        <ConverterWrapper>
          <Title>{nameCapitalized(name)} Unit Converter</Title>

          <Columns>
            <Column>
              <ConverterSideA>
                <form
                  onSubmit={e => {
                    e.preventDefault()
                  }}
                >
                  <input
                    type="number"
                    aria-label="AA"
                    maxLength="20"
                    value={valueOne}
                    onChange={inputOne}
                  />
                  <label>
                    <select
                      ref={selectOne}
                      aria-label="AAS"
                      onBlur={changeSelectOne}
                      onChange={changeSelectOne}
                    >
                      {units.map((unit, index) => {
                        return (
                          <option
                            key={`first-${index}`}
                            value={unit.multiplier}
                            data-unit={unit.offset}
                          >
                            {unit.name} {unit.symbol ? `(${unit.symbol})` : ""}
                          </option>
                        )
                      })}
                    </select>
                  </label>
                </form>
              </ConverterSideA>
            </Column>
            <ConverterEquals>
              <span>&#61;</span>
            </ConverterEquals>
            <Column>
              <ConverterSideB>
                <form
                  onSubmit={e => {
                    e.preventDefault()
                  }}
                >
                  <input
                    type="number"
                    aria-label="BB"
                    maxLength="20"
                    value={valuetwo}
                    onChange={inputtwo}
                  />
                  <label>
                    <select
                      ref={selectTwo}
                      aria-label="BBS"
                      onBlur={changeSelectTwo}
                      onChange={changeSelectTwo}
                    >
                      {units.map((unit, index) => {
                        return (
                          <option
                            key={`second-${index}`}
                            value={unit.multiplier}
                            data-unit={unit.offset}
                            //  {unit.offset ? unit.offset : ""}
                          >
                            {unit.name} {unit.symbol ? `(${unit.symbol})` : ""}
                          </option>
                        )
                      })}
                    </select>
                  </label>
                </form>
              </ConverterSideB>
            </Column>
          </Columns>
        </ConverterWrapper>
        <br />
        <UnitInfo
          name={name}
          symbolNode={symbolNode}
          baseUnitNode={baseUnitNode}
          derivedNode={derivedNode}
          info={info}
        />
        <br />
        {name === "temperature" ? (
          units.map((e, index) => {
            return (
              <p
                key={index}
                dangerouslySetInnerHTML={{
                  __html: e.notes,
                }}
              />
            )
          })
        ) : (
          <UnitEqualAnathor units={units} />
        )}
        <br />
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <ListUnitsLink pathpages={pathpages} />
          </React.Suspense>
        )}
      </div>
      <br />
    </Layout>
  )
}

export default IndexPage
