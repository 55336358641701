import React from 'react';
import { nameCapitalized } from '../../../components/Script/script';

const UnitEqualAnathor = ({ units }) => {
  return (
    <>
      <div>
        {units.map((unit, index) => {
          const arrayUnit = units;
          let result;
          if (arrayUnit[index + 1] !== undefined) {
            result = (1 * unit.multiplier) / arrayUnit[index + 1].multiplier;
          }
          return (
            <div key={`three-${index}`}>
              {arrayUnit[index + 1] !== undefined ? (
                <div>
                  <h2 className="h5">
                    {nameCapitalized(unit.name)} {unit.symbol ? `(${unit.symbol})` : ''} to{' '}
                    {nameCapitalized(arrayUnit[index + 1].name)}{' '}
                    {arrayUnit[index + 1].symbol ? `(${arrayUnit[index + 1].symbol})` : ''}
                  </h2>
                  <p className="mb-4">
                    One {unit.name} {unit.symbol ? `(${unit.symbol})` : ''} is equal to {result}{' '}
                    {arrayUnit[index + 1].name}{' '}
                    {arrayUnit[index + 1].symbol ? `(${arrayUnit[index + 1].symbol}).` : '.'}
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default React.memo(UnitEqualAnathor);
