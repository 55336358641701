import styled from "styled-components"

export const ConverterWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  & input {
    border: solid 0.5px #34495e;
    font-size: 1em;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    outline: none;
  }
  & label,
  p {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    & select,
    p {
      background-color: #f7f9fa;
      border: solid 0.5px #34495e;
      font-size: inherit;
      padding: 10px;
      width: 100%;
      margin: 0;
      border-radius: 4px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      text-indent: 0.01px;
      text-overflow: "";
      -webkit-appearance: none;
      font-size: 1em;
      font-weight: bold;
      outline: none;
      text-transform: capitalize;
    }
  }
  & label:not(.withoutArrow) {
    &:after {
      content: "";
      position: absolute;
      pointer-events: none;
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      height: 1em;
      font-size: 0.625em;
      line-height: 1;
      right: 1.5em;
      top: 50%;
      margin-top: -0.5em;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
    &:before {
      content: "";
      position: absolute;
      pointer-events: none;
      width: 2.5em;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 0;
      border-radius: 4px;
    }
  }
`

export const ConverterSideA = styled.div`
  margin-top: 10px;
  box-sizing: border-box;
  width: 100%;
  padding-right: 10px;
  @media screen and (max-width: 1023px) {
    padding: 0;
  }
`
export const ConverterSideB = styled.div`
  margin-top: 10px;
  box-sizing: border-box;
  width: 100%;
  padding-left: 10px;
  @media screen and (max-width: 1023px) {
    padding: 0;
  }
`

export const ConverterEquals = styled.div`
  max-width: 10%;
  font-size: 2em;
  margin: auto;
  padding: 0;
`
