import React from 'react';
import { nameCapitalized } from '../../../components/Script/script';

const UnitInfo = ({ name, symbolNode, baseUnitNode, derivedNode, info }) => {
  return (
    <>
      <p>
        {nameCapitalized(name)}{' '}
        {symbolNode
          ? `symbol "
            ${symbolNode}", base unit is ${baseUnitNode}`
          : ''}{' '}
        {derivedNode ? `, ${name} derived from ${derivedNode}.` : '.'}
        <br />
        {info}
      </p>
    </>
  );
};

export default React.memo(UnitInfo);
